<template>
  <v-dialog
    v-model="show"
    @click:outside="$emit('close')"
    scrollable
    width="75vw"
    class="dialog"
  >
    <v-card v-if="anyItem">
      <v-card-title class="text-h5 primary white--text pb-6">
        <slot name="title">
          Details for {{ name }}
          version {{ version }}
          on {{ environment.name }}
        </slot>
      </v-card-title>

      <v-card-text class="pa-0">
        <v-text-field
          v-model="filter"
          append-icon="mdi-magnify"
          :label="labels.search"
          single-line
          hide-details
          class="pa-2"
        />
        <v-row
          v-for="(item, index) in filteredDialogItems"
          :key="item.name"
          :class="index %2 ? 'secondary white--text' : 'background'"
          no-gutters
        >
          <v-col
            cols="3"
            class="flex-shrink-1 flex-grow-0 px-3"
          >
            <pre>{{ item.name }}</pre>
          </v-col>
          <v-col
            cols="9"
            class="flex-shrink-0 flex-grow-1 px-3"
          >
            <p class="code">{{ item.value || "--null--" }}</p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="$emit('close')"
        >
          {{ labels.close }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapState } from 'vuex';
import { labels } from '@/assets/texts.json';

export default {
  name: 'detailsDialog',
  props: {
    dialogItems: {
      type: Array[Object],
      required: false,
      default: () => [{}],
    },
    name: {
      type: String,
      default: '',
    },
    version: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('fetch', [
      'environment',
    ]),
    anyItem() {
      return !isEmpty(this.dialogItems);
    },
    filteredDialogItems() {
      return this.dialogItems.filter((item) => item.name.includes(this.filter));
    },
  },
  data: () => ({
    labels,
    filter: '',
  }),
};
</script>

<style scoped>
  .dialog {
    z-index: 9999;
  }
  .code {
    font-family: monospace;
  }
</style>
